// This is called a "splat route" and as it's in the root `/app/routes/`
// directory, it's a catchall. If no other routes match, this one will and we
// can know that the user is hitting a URL that doesn't exist.

import { Link, useLocation } from '@remix-run/react'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { Button } from '#app/components/ui/button'
import { Icon } from '#app/components/ui/icon.tsx'

export default function NotFound() {
	return <ErrorBoundary />
}

export function ErrorBoundary() {
	const location = useLocation()
	return (
		<GeneralErrorBoundary
			statusHandlers={{
				404: () => (
					<div className="absolute inset-0 m-auto flex flex-col items-center justify-center bg-accent">
						<div className="flex flex-col items-center justify-center rounded-lg border-2 border-gray-200 bg-white p-8 text-center">
							<div className="flex flex-col gap-3">
								<h1>Tato stránka neexistuje</h1>
								<pre className="mt-4 whitespace-pre-wrap break-all text-body-sm">
									Hledanou stránku {location.pathname} jsme nenašli. <br />
									Zkuste adresu upravit, nebo se vraťte zpět na hlavní stránku.
								</pre>
							</div>
							<Link
								to="/"
								className="mt-8 text-body-md underline"
								prefetch="intent"
							>
								<Button variant="outline">
									<Icon name="arrow-left" />
									Zpět na hlavní stránku
								</Button>
							</Link>
						</div>
					</div>
				),
			}}
		/>
	)
}
